import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "row q-col-gutter-sm" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = ["alt", "title", "src"]
const _hoisted_5 = {
  key: 1,
  class: "full-width bg-grey-3",
  style: {"aspect-ratio":"1","border-radius":"15px"}
}
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = { class: "q-my-none text-h5 q-mb-sm text-muted" }
const _hoisted_8 = { class: "q-my-none q-mb-sm text-h4" }

import {PropType} from 'vue';
import {openExternalLink} from 'src/etc/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogListItem',
  props: {
  modelValue: {
    type: Object as PropType<any>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("a", {
    href: __props.modelValue.link,
    style: {"text-decoration":"none"},
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(openExternalLink)(__props.modelValue.link)), ["prevent"]))
  }, [
    _withDirectives((_openBlock(), _createBlock(_component_q_card, {
      flat: "",
      bordered: "",
      class: "full-height",
      style: {"border-radius":"25px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (__props.modelValue.header_image)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "full-width",
                      alt: `Blog-Artikel: ${__props.modelValue.title}`,
                      title: __props.modelValue.title,
                      style: {"aspect-ratio":"1","object-fit":"cover","border-radius":"15px"},
                      src: __props.modelValue.header_image
                    }, null, 8, _hoisted_4))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5))
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, _toDisplayString(__props.modelValue.pre_title), 1),
                _createElementVNode("h2", _hoisted_8, _toDisplayString(__props.modelValue.title), 1),
                _createElementVNode("p", null, _toDisplayString(__props.modelValue.teaser), 1)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })), [
      [_directive_ripple]
    ])
  ], 8, _hoisted_1))
}
}

})