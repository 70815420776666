import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative-position" }

import {ref, watch} from 'vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'CustomSwiperContainer',
  props: {
  slidesData: {
    type: Array,
    default: () => [],
  },
  navigation: {
    type: Boolean,
    default: false
  }
},
  setup(__props, { expose: __expose }) {

const props = __props

const swiperEle = ref<HTMLElement | any | null>(null)
const swiperIsBeginning = ref(true)
const swiperIsEnd = ref(false)
const canScroll = ref(false)


function next() {
  swiperEle.value?.swiper?.slideNext()
  swiperIsEnd.value = swiperEle.value?.swiper.isEnd
  swiperIsBeginning.value = swiperEle.value?.swiper.isBeginning
}


function prev() {
  swiperEle.value?.swiper?.slidePrev()
  swiperIsEnd.value = swiperEle.value?.swiper.isEnd
  swiperIsBeginning.value = swiperEle.value?.swiper.isBeginning
}

watch(
  () => props.slidesData,
  () => {
    canScroll.value = props.slidesData?.length > 1
  }
)

watch(
  () => swiperEle.value,
  () => {
    if (swiperEle.value && swiperEle.value?.swiper) {
      swiperEle.value.swiper.on('activeIndexChange', (swiper: any) => {
        swiperIsEnd.value = swiper.isEnd
        swiperIsBeginning.value = swiper.isBeginning
      })

      canScroll.value = props.slidesData?.length > 1
    } else {
      console.warn('swiperEle not found')
    }
  },
  {once: true}
)

__expose({
  swiperEle,
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("swiper-container", _mergeProps({
      ref_key: "swiperEle",
      ref: swiperEle
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "slides", {}, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.slidesData, (slideData, i) => {
          return (_openBlock(), _createElementBlock("swiper-slide", { key: i }, [
            _renderSlot(_ctx.$slots, "default", {
              slideData: (slideData as any),
              i: i
            })
          ]))
        }), 128))
      ])
    ], 16),
    (__props.navigation && canScroll.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: "c-swiper-navigation c-swiper-navigation__left q-pa-sm",
            onClick: _withModifiers(prev, ["prevent"])
          }, [
            _createVNode(_component_q_btn, {
              color: "white",
              size: "0.8rem",
              "text-color": "dark",
              disable: swiperIsBeginning.value,
              round: "",
              dense: "",
              ripple: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "fas fa-chevron-left",
                  size: "1rem"
                })
              ]),
              _: 1
            }, 8, ["disable"])
          ]),
          _createElementVNode("div", {
            class: "c-swiper-navigation c-swiper-navigation__right q-pa-sm",
            onClick: _withModifiers(next, ["prevent"])
          }, [
            _createVNode(_component_q_btn, {
              color: "white",
              size: "0.8rem",
              disable: swiperIsEnd.value,
              "text-color": "dark",
              round: "",
              dense: "",
              ripple: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "fas fa-chevron-right",
                  size: "0.9rem"
                })
              ]),
              _: 1
            }, 8, ["disable"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})