import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center q-gutter-x-sm text-bold" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "cursor"
}
const _hoisted_4 = {
  class: "flex no-wrap",
  style: {"column-gap":"8px"}
}
const _hoisted_5 = {
  class: "flex no-wrap",
  style: {"column-gap":"8px"}
}

import {useMainStore} from 'stores/main';
import {computed, inject} from 'vue';
import {getAccountPageParams, setBusinessAccount} from 'src/etc/helper';
import {QHeader} from 'quasar';
import {openCreateChooserDialog} from 'src/apps/core/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderToolbar',
  props: {
  tag: {default: QHeader},
  color: {default: 'white'}
},
  setup(__props) {



const mainStore = useMainStore()
const drawer = inject('drawer')

const logout = function () {
  mainStore.logout();
}

const hasHistory = computed(() => {
  if (typeof window === 'undefined') return false
  return window && window.history?.length > 0 && window.history.state?.back !== null
})


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_toolbar, { class: "q-px-none flex q-gutter-x-xs" }, {
    default: _withCtx(() => [
      (_ctx.$q.screen.lt.sm && (!_ctx.$route.meta.hasBackBtn || !hasHistory.value))
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            dense: "",
            flat: "",
            icon: "menu",
            "aria-label": "Menu Button",
            "no-caps": "",
            round: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (drawer.value = !_unref(drawer)))
          }))
        : _createCommentVNode("", true),
      ( ((!_ctx.$route.meta.hasBackBtn || !hasHistory.value )) && _ctx.$q.screen.gt.xs)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: "/",
            class: "q-px-xs",
            style: {"text-decoration":"unset"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: require('assets/campertrader/logo/campertrader_icon_ocean.svg'),
                  width: "25",
                  alt: "Camper Trader Logo"
                }, null, 8, _hoisted_2),
                _cache[4] || (_cache[4] = _createElementVNode("div", {
                  class: "text-left text-inter text-secondary",
                  style: {"line-height":"0.8rem"}
                }, [
                  _createElementVNode("div", null, "Camper"),
                  _createElementVNode("div", { style: {"margin-left":"10px"} }, "Trader")
                ], -1))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.$route.meta.hasBackBtn && hasHistory.value)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 2,
            flat: "",
            icon: "fas fa-arrow-left",
            round: "",
            "aria-label": "Back Button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1)))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_q_space),
      (!_ctx.$q.screen.lt.sm)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 3,
            flat: "",
            "no-caps": "",
            href: "/blog/",
            "aria-label": "Zum Blog Button"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Blog ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.$q.screen.lt.sm)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 4,
            flat: "",
            "no-caps": "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(openCreateChooserDialog)(_ctx.$q, _unref(mainStore))))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Verkaufen ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.$q.screen.lt.sm)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 5,
            to: {name: 'search'},
            dense: "",
            flat: "",
            "no-caps": "",
            "aria-label": "Zur Suche Button"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Suchen ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.$q.screen.gt.sm && _unref(mainStore).isLoggedIn)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 6,
            to: {name: 'favorites'},
            flat: "",
            round: "",
            "aria-label": "Zu den Favoriten Button"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "far fa-bookmark",
                size: "xs"
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(mainStore).isLoggedIn && _unref(mainStore).accountDetail)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
            _createVNode(_component_q_btn, {
              to: {name: 'messenger'},
              flat: "",
              round: "",
              "aria-label": "Zum Messenger Button"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "far fa-message",
                  size: "xs"
                }),
                (_unref(mainStore).messagesUnread > 0)
                  ? (_openBlock(), _createBlock(_component_q_badge, {
                      key: 0,
                      color: "red",
                      floating: "",
                      style: {"bottom":"4px","top":"unset"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(mainStore).messagesUnread), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_btn_dropdown, {
              class: "no-icon",
              dense: "",
              flat: "",
              "no-caps": "",
              square: "",
              "aria-label": "Profil Aktionen Dropdown"
            }, {
              label: _withCtx(() => [
                _createVNode(_component_q_avatar, { size: "sm" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_img, {
                      "no-transition": "",
                      "no-spinner": "",
                      "no-native-menu": "",
                      src: _unref(mainStore).accountDetail?.active_account?.avatar?.path
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }),
                (_unref(mainStore).isLoggedIn && !_ctx.$q.screen.lt.md)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, "   " + _toDisplayString(_unref(mainStore).accountDetail?.active_account?.is_business ?
                _unref(mainStore).accountDetail?.active_account.public_name :
                _unref(mainStore).accountDetail?.email), 1))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_q_list, { style: {"min-width":"200px"} }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      to: _unref(getAccountPageParams)(),
                      clickable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Profil")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"])), [
                      [_directive_close_popup]
                    ]),
                    (_unref(mainStore).accountDetail?.businesses?.length)
                      ? (_openBlock(), _createBlock(_component_q_expansion_item, {
                          key: 0,
                          "dense-toggle": "",
                          "expand-separator": "",
                          label: "Alle Profile"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item, {
                                  active: _unref(mainStore).accountDetail.active_account.uuid === _unref(mainStore).accountDetail.uuid,
                                  class: "bg-grey-3",
                                  clickable: "",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(setBusinessAccount)(null, _ctx.$router)))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_4, [
                                          _createVNode(_component_q_avatar, { size: "xs" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_img, {
                                                "no-spinner": "",
                                                src: _unref(mainStore).accountDetail?.avatar?.path
                                              }, null, 8, ["src"])
                                            ]),
                                            _: 1
                                          }),
                                          _createElementVNode("div", null, _toDisplayString(_unref(mainStore).accountDetail.public_name), 1)
                                        ])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["active"]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mainStore).accountDetail.businesses, (business) => {
                                  return (_openBlock(), _createBlock(_component_q_item, {
                                    key: business.slug,
                                    active: _unref(mainStore).accountDetail.active_account.uuid === business.uuid ,
                                    class: "bg-grey-3",
                                    clickable: "",
                                    onClick: ($event: any) => (_unref(setBusinessAccount)(business.slug, _ctx.$router))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_5, [
                                            _createVNode(_component_q_avatar, { size: "xs" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_img, {
                                                  src: business.avatar?.path
                                                }, null, 8, ["src"])
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createElementVNode("div", null, _toDisplayString(business.public_name), 1)
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["active", "onClick"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      to: {name: 'search-agents'},
                      clickable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("Suchaufträge")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      to: {name: 'favorites'},
                      clickable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("Deine Merkliste")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      to: {name: 'settings'},
                      clickable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Einstellungen")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_close_popup]
                    ]),
                    _createVNode(_component_q_separator),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      onClick: logout
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("Logout")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : (_openBlock(), _createBlock(_component_q_btn, {
            key: 8,
            to: {name: 'login'},
            class: "q-mx-sm",
            unelevated: "",
            "no-caps": "",
            color: "dark"
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode(" Anmelden ")
            ])),
            _: 1
          }))
    ]),
    _: 1
  }))
}
}

})