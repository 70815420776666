import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "absolute-bottom text-white text-right",
  style: {"background":"unset"}
}
const _hoisted_3 = { class: "flex column justify-between full-height" }
const _hoisted_4 = { class: "flex justify-between full-width" }
const _hoisted_5 = { class: "text-grey-8" }
const _hoisted_6 = {
  key: 0,
  class: "text-bold q-mr-md"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-grey-8" }
const _hoisted_9 = { class: "flex column full-width" }
const _hoisted_10 = { class: "flex column q-gutter-y-sm full-width" }
const _hoisted_11 = { class: "text-h4" }
const _hoisted_12 = {
  key: 0,
  class: "flex items-center q-gutter-sm q-mt-xs"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "flex items-center q-gutter-sm" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "q-pb-md" }
const _hoisted_17 = { class: "text-h3" }
const _hoisted_18 = { class: "flex q-gutter-x-sm" }
const _hoisted_19 = { style: {"font-size":"0.75rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_profile_name = _resolveComponent("profile-name")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_advert_card = _resolveComponent("advert-card")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    target: "_blank",
    to: {name: 'listingDetail', params: {slug: $props.advert.seo_slug, uuid: $props.advert.uuid}},
    class: "c-wrapper"
  }, {
    default: _withCtx(() => [
      (!$props.asCard)
        ? (_openBlock(), _createBlock(_component_q_card, {
            key: 0,
            disabled: $props.loading ? true : undefined,
            bordered: "",
            flat: "",
            square: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_q_img, {
                  "no-spinner": "",
                  ratio: 4/3,
                  src: $props.advert.cover_image?.image_thumbnail?.path,
                  "show-placeholder": ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_icon, { name: "fas fa-images" }),
                        _createTextVNode("  " + _toDisplayString($props.advert.images_count), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["src"]),
                _createVNode(_component_q_card_section, { class: "col" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            ($props.advert.distance_in_km)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                  _createVNode(_component_q_icon, { class: "fas fa-location-pin" }),
                                  _createTextVNode(" " + _toDisplayString($props.advert.distance_in_km) + " km", 1)
                                ]))
                              : _createCommentVNode("", true),
                            ($props.advert.address)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.advert.address.city) + ", " + _toDisplayString($props.advert.address.state), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_8, _toDisplayString($options.parseDate($props.advert?.created_at)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_11, _toDisplayString($props.advert.title?.trim()), 1),
                            _createElementVNode("div", null, _toDisplayString($props.advert.vehicle.car_type_display), 1)
                          ]),
                          ($props.showOwner)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createVNode(_component_q_avatar, { size: "sm" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("img", {
                                      src: $props.advert.by_user?.avatar?.path
                                    }, null, 8, _hoisted_13)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_profile_name, {
                                  owner: $props.advert.by_user,
                                  target: $props.target
                                }, {
                                  name: _withCtx(({user}) => [
                                    _createElementVNode("span", null, _toDisplayString(user.public_name ?? user.name), 1)
                                  ]),
                                  _: 1
                                }, 8, ["owner", "target"])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_14, [
                            ($props.advert?.vehicle?.seats)
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 0,
                                  class: "text-black",
                                  color: "grey-3",
                                  style: {"height":"24px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "airline_seat_recline_normal",
                                      size: "0.85rem"
                                    }),
                                    _createTextVNode(" " + _toDisplayString($props.advert.vehicle.seats), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            ($props.advert?.vehicle?.beds)
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 1,
                                  class: "text-black",
                                  color: "grey-3",
                                  style: {"height":"24px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "fas fa-bed",
                                      size: "0.75rem"
                                    }),
                                    _createTextVNode("  " + _toDisplayString($props.advert.vehicle.beds), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            ($props.advert?.vehicle?.mileage)
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 2,
                                  class: "text-black",
                                  color: "grey-3",
                                  style: {"height":"24px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "fas fa-gauge",
                                      size: "0.75rem"
                                    }),
                                    _createTextVNode("  " + _toDisplayString(_ctx.$filters.i18nNotation({
                          value: $props.advert?.vehicle?.mileage,
                        })) + " km ", 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            ($props.advert?.vehicle?.fuel_type)
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 3,
                                  class: "text-black",
                                  color: "grey-3",
                                  style: {"height":"24px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "fas fa-gas-pump",
                                      size: "0.75rem"
                                    }),
                                    _createTextVNode("  " + _toDisplayString($props.advert?.vehicle?.fuel_type_display), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          ($props.advert?.is_virtual_tour)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createVNode(_component_q_badge, {
                                  class: "text-black",
                                  outline: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_img, {
                                      src: require('src/assets/360-camera_64px_grey.png'),
                                      "no-spinner": "",
                                      "no-transition": "",
                                      width: "18px"
                                    }, null, 8, ["src"]),
                                    _cache[0] || (_cache[0] = _createTextVNode("  Online-Besichtigung möglich "))
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _createTextVNode(_toDisplayString(_ctx.$filters.currency({
                          value: $props.advert.price,
                          minimumFractionDigits: 0
                        })) + " ", 1),
                              ($props.advert.is_negotiable)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(" VB")
                                  ], 64))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }),
                ($props.showExtras && $props.advert.vehicle.extras.length)
                  ? (_openBlock(), _createBlock(_component_q_card_section, {
                      key: 0,
                      class: "col-12"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.advert.vehicle.extras, (extra) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: extra.id
                            }, [
                              _createTextVNode(_toDisplayString(extra.icon) + " ", 1),
                              _createElementVNode("span", _hoisted_19, _toDisplayString(extra.name), 1)
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["disabled"]))
        : (_openBlock(), _createBlock(_component_advert_card, {
            key: 1,
            data: $props.advert,
            "show-owner": $props.showOwner,
            disabled: $props.loading ? true : undefined,
            target: $props.target
          }, null, 8, ["data", "show-owner", "disabled", "target"]))
    ]),
    _: 1
  }, 8, ["to"]))
}