<template>
  <div class="flex q-gutter-md justify-center q-my-lg">
    <a
      href="https://wa.me/4915679366374" rel="nofollow"
      style="text-decoration: none"
      aria-label="Link to WhatsApp"
      target="_blank">
      <q-icon name="fab fa-whatsapp" size="lg"/>
    </a>
    <a
      href="https://www.instagram.com/campertrader.de/" rel="nofollow"
      style="text-decoration: none"
      aria-label="Link to Instagram"
      target="_blank">
      <q-icon name="fab fa-instagram" size="lg"/>
    </a>
    <!--    <a href="https://twitter.com/campertrader_de/" rel="nofollow"-->
    <!--       style="text-decoration: none"-->
    <!--       target="_blank">-->
    <!--      <q-icon name="fab fa-twitter" size="lg"/>-->
    <!--    </a>-->
    <a
      href="https://www.facebook.com/campertrader.de/" rel="nofollow"
      style="text-decoration: none"
      aria-label="Link to Facebook"
      target="_blank">
      <q-icon name="fab fa-facebook" size="lg"/>
    </a>
    <!--    <a href="https://www.pinterest.com/267unr7902cwk5fep8ft0k3vgyyp9p/" rel="nofollow"-->
    <!--       style="text-decoration: none"-->
    <!--       target="_blank">-->
    <!--      <q-icon name="fab fa-pinterest" size="lg"/>-->
    <!--    </a>-->
    <a
      href="https://www.linkedin.com/company/campertrader/" rel="nofollow"
      style="text-decoration: none"
      aria-label="Link to LinkedIn"
      target="_blank">
      <q-icon name="fab fa-linkedin" size="lg"/>
    </a>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
</style>
