import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = {
  class: "flex column text-h4",
  style: {"gap":"12px"}
}
const _hoisted_3 = ["href"]

import {onMounted, ref} from 'vue';
import Blog from 'src/api/blog';
import {axiosConsoleError} from 'src/apps/core/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'MoreInfoBlogFeed',
  setup(__props) {


const articles = ref<any>([])

const getFeed = async function () {
  Blog.getBlogFeed()
    .then((resp) => {
      if (typeof resp.data !== 'object') {
        console.error('Blog feed is not an object');
        return;
      }
      articles.value = resp.data.articles;

    })
    .catch((err) => {
      axiosConsoleError(err)
    });
}

onMounted(() => {
  getFeed()
})

return (_ctx: any,_cache: any) => {
  return (articles.value.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h2 text-weight-light q-mb-lg" }, "Weitere Informationen", -1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articles.value, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.title
            }, [
              _createElementVNode("a", {
                href: item?.link,
                target: "_blank"
              }, _toDisplayString(item?.title), 9, _hoisted_3)
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})