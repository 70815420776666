import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import {toggleAdvertFavorite, toggleItemFavorite} from 'src/etc/helper';
import {computed, PropType, ref} from 'vue';
import {Advert} from 'src/models/advert';
import {Item} from 'src/models/market';
import {askUserForRating} from 'src/apps/core/utils';
import {useMainStore} from 'stores/main';
import {useQuasar} from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookmarkBtn',
  props: {
  obj: {required: true, type: Object as PropType<Advert | Item>},
  type: {default: 'advert', type: String as () => 'advert' | 'item'}, // button, icon
  round: {default: true, type: Boolean},
  color: {default: 'dark', type: String},
},
  setup(__props) {


const loading = ref(false)

const props = __props

const mainStore = useMainStore()
const $q = useQuasar()

function toggleFavorite(data: Advert | Item) {
  loading.value = true
  askUserForRating($q, mainStore)
  if (props.type === 'advert') {
    toggleAdvertFavorite(data).finally(() => {
      loading.value = false
    })
  } else if (props.type === 'item') {
    toggleItemFavorite(data).finally(() => {
      loading.value = false
    })
  } else {
    console.warn('unknown type', props.type)
    loading.value = false
  }
}

const attrsCmp = computed(() => {
  if (props.round) {
    return {
      flat: true,
      round: true,
      textColor: props.obj?.is_favorite ? 'secondary' : undefined,
      style: 'color: rgba(0,0,0,0.5);'
    }
  }
  return {
    noCaps: true,
    outline: true,
    textColor: props.obj?.is_favorite ? 'secondary' : props.color,
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, _mergeProps({
    class: "bookmark-btn",
    loading: loading.value
  }, attrsCmp.value, {
    push: "",
    "aria-label": "Als Favorit speichern",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleFavorite(__props.obj)), ["prevent"]))
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_q_icon, { name: "fas fa-bookmark c-fav-icon" }),
        (_ctx.$slots.label)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[1] || (_cache[1] = _createTextVNode("  ")),
              _renderSlot(_ctx.$slots, "label")
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["loading"]))
}
}

})