import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-my-xl q-py-xl container"
}
const _hoisted_2 = { class: "row q-col-gutter-sm" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "row q-col-gutter-sm" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = ["alt", "title", "src"]
const _hoisted_7 = {
  key: 1,
  class: "full-width bg-grey-3",
  style: {"aspect-ratio":"1","border-radius":"15px"}
}
const _hoisted_8 = { class: "col-8" }
const _hoisted_9 = { class: "q-my-none text-h5 q-mb-sm text-muted" }
const _hoisted_10 = { class: "q-my-none q-mb-sm text-h4" }
const _hoisted_11 = { class: "flex justify-center q-mt-lg" }

import {onMounted, ref} from 'vue';
import Blog from 'src/api/blog';
import {axiosConsoleError} from 'src/apps/core/utils';



export default /*@__PURE__*/_defineComponent({
  __name: 'BlogFeed',
  setup(__props) {


const feed = ref<any>()
const articles = ref([])

const getFeed = async function () {
  Blog.getBlogFeed()
    .then((resp) => {
      if (typeof resp.data !== 'object') {
        console.error('Blog feed is not an object');
        return;
      }
      feed.value = resp.data;
      articles.value = resp.data.articles?.slice(0, 8);

    })
    .catch((err) => {
      axiosConsoleError(err)
    });
}

onMounted(() => {
  getFeed()
})


return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (articles.value?.length)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articles.value, (article) => {
              return (_openBlock(), _createElementBlock("div", {
                key: article.title,
                class: "col-12 col-md-6"
              }, [
                _createElementVNode("a", {
                  href: article.link,
                  style: {"text-decoration":"none"}
                }, [
                  _withDirectives((_openBlock(), _createBlock(_component_q_card, {
                    flat: "",
                    bordered: "",
                    class: "full-height",
                    style: {"border-radius":"25px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              (article?.header_image)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "full-width",
                                    alt: `Blog-Artikel: ${article.title}`,
                                    title: article.title,
                                    style: {"aspect-ratio":"1","object-fit":"cover","border-radius":"15px"},
                                    src: article.header_image
                                  }, null, 8, _hoisted_6))
                                : (_openBlock(), _createElementBlock("div", _hoisted_7))
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("h3", _hoisted_9, _toDisplayString(article.pre_title), 1),
                              _createElementVNode("h2", _hoisted_10, _toDisplayString(article.title), 1),
                              _createElementVNode("p", null, _toDisplayString(article.teaser), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)), [
                    [_directive_ripple]
                  ])
                ], 8, _hoisted_3)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_q_btn, {
              color: "primary",
              flat: "",
              href: "/blog"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Weiter zum Blog ")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (articles.value?.length)
      ? (_openBlock(), _createBlock(_component_q_separator, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})