import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fullscreen text-center q-pa-md flex flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createVNode(_component_q_img, {
          src: require('assets/noun-crash-4860217.svg')
        }, null, 8, ["src"])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h2" }, " Ups... Eine Sackgasse ... ", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", null, "Diese Seite konnte leider nicht gefunden werden.", -1)),
      _createVNode(_component_q_btn, {
        class: "q-mt-xl",
        color: "dark",
        label: "Zur Startseite",
        to: "/",
        rounded: "",
        "no-caps": ""
      })
    ])
  ]))
}