

import type {PropType} from 'vue';
import type {Advert} from 'src/models/advert';
import AdvertCard from 'src/apps/adverts/components/AdvertCard.vue';
import ProfileName from 'src/components/etc/ProfileName.vue';

export default {
  name: 'FilterListItem',
  components: {ProfileName, AdvertCard},
  props: {
    advert: {
      required: true,
      type: Object as PropType<Advert>
    },
    loading: {
      default: false
    },
    showExtras: {
      type: Boolean,
      default: false,
    },
    showOwner: {
      type: Boolean,
      default: true,
    },
    asCard: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
    },
  },
  methods: {
    parseDate: (dateVal) => {
      return new Date(dateVal).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      })
    }
  },
}
