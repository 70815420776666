import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "bg-grey-3 rounded-borders relative-position text-white",
  style: {"aspect-ratio":"1"}
}
const _hoisted_2 = { class: "absolute-full rounded-borders overflow-hidden c-scale" }
const _hoisted_3 = { class: "absolute-bottom full-width" }
const _hoisted_4 = {
  class: "q-pa-md flex",
  style: {"gap":"12px"}
}
const _hoisted_5 = {
  class: "text-white font-inter-bold ellipsis-3-lines",
  style: {"font-size":"1.3rem","line-height":"1.7rem"}
}
const _hoisted_6 = { class: "bg-black" }
const _hoisted_7 = {
  class: "flex no-wrap full-width items-center",
  style: {"gap":"12px"}
}
const _hoisted_8 = { class: "font-inter-bold" }
const _hoisted_9 = { class: "text-small" }
const _hoisted_10 = { class: "flex no-wrap justify-between items-center" }
const _hoisted_11 = {
  class: "flex no-wrap",
  style: {"gap":"6px"}
}
const _hoisted_12 = {
  class: "flex no-wrap items-center",
  style: {"gap":"4px"}
}
const _hoisted_13 = { class: "flex no-wrap" }
const _hoisted_14 = { class: "text-bold" }
const _hoisted_15 = { class: "text-small q-px-xs text-muted" }

import CustomLink from 'components/etc/CustomLink.vue'
import {onMounted, onUnmounted, ref} from 'vue';
import {useQuasar} from 'quasar';
import {humanizeDate} from 'src/etc/helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'StoryListItem',
  props: {
  modelValue: Object,
},
  setup(__props) {




const isVisible = ref(false);
const cPlay = ref<HTMLElement | null>(null);

let observer: IntersectionObserver | null = null;
const $q = useQuasar()

onMounted(() => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        isVisible.value = entry.isIntersecting;
      })
    },
    {threshold: 0.8} // Adjust threshold to when the element is considered visible (50% visible)
  )

  if (cPlay.value) {
    observer.observe(cPlay.value)
  }
})


onUnmounted(() => {
  if (cPlay.value && observer) {
    observer.unobserve(cPlay.value)
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "cPlay",
    ref: cPlay,
    class: _normalizeClass({'is-visible': isVisible.value, 'is-mobile': _unref($q).platform.is.mobile})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createBlock(CustomLink, {
        to: {name: 'stories-detail', params: {slug: __props.modelValue?.slug}},
        style: {"color":"unset"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_img, {
              "no-spinner": "",
              src: __props.modelValue?.cover_image?.path,
              class: "full-width full-height",
              fit: "cover"
            }, null, 8, ["src"])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute-full c-bg-dimmer rounded-borders" }, null, -1))
        ]),
        _: 1
      }, 8, ["to"])), [
        [_directive_ripple]
      ]),
      _withDirectives((_openBlock(), _createBlock(CustomLink, {
        to: {name: 'stories-detail', params: {slug: __props.modelValue?.slug}},
        style: {"color":"unset"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(__props.modelValue?.title), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createVNode(_component_q_avatar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_img, {
                        "no-spinner": "",
                        src: __props.modelValue?.interviewee?.avatar?.path,
                        alt: `Avatar of ${__props.modelValue?.interviewee.name}`
                      }, null, 8, ["src", "alt"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(__props.modelValue?.interviewee.name), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(__props.modelValue?.interviewee.caption), 1)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["to"])), [
        [_directive_ripple]
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (__props.modelValue?.reactions_count?.count)
          ? (_openBlock(), _createBlock(_component_q_chip, {
              key: 0,
              color: "white",
              class: "bordered q-px-sm q-mx-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue?.reactions_count?.emojis, (rec) => {
                      return (_openBlock(), _createElementBlock("div", { key: rec }, _toDisplayString(rec), 1))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(__props.modelValue?.reactions_count?.count), 1)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(humanizeDate)(__props.modelValue?.created_at)), 1)
    ])
  ], 2))
}
}

})