import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "componentRef",
  class: "c-item-card relative-position"
}
const _hoisted_2 = {
  key: 1,
  class: "relative-position bg-grey-3 rounded-borders overflow-hidden",
  style: {"aspect-ratio":"3/4"}
}
const _hoisted_3 = { class: "q-mt-sm" }
const _hoisted_4 = { class: "q-px-xs" }
const _hoisted_5 = {
  class: "q-mb-sm ellipsis-3-lines font-inter",
  style: {"line-height":"1rem"}
}
const _hoisted_6 = { class: "flex text-small q-gutter-x-xs no-wrap" }
const _hoisted_7 = { class: "text-grey-8 ellipsis" }
const _hoisted_8 = { class: "flex q-gutter-x-xs q-mt-xs" }
const _hoisted_9 = {
  class: "text-bold overflow-hidden ellipsis",
  style: {"white-space":"nowrap","font-size":"0.9rem"}
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "q-mt-sm" }
const _hoisted_12 = { class: "q-px-xs" }
const _hoisted_13 = { class: "q-mt-md" }

import {computed, PropType, ref} from 'vue';
import {Item} from 'src/models/market';
import CustomLink from 'components/etc/CustomLink.vue';
import ItemCardImage from 'src/apps/market/components/ItemCardImage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemCard',
  props: {
  modelValue: {type: Object as PropType<Item>, default: undefined},
  target: {default: undefined, type: String},
  loading: {default: false, type: Boolean},
  showDistance: {default: true, type: Boolean},
  showSlider: {default: true, type: Boolean},
  isVisible: {default: true, type: Boolean},
},
  setup(__props) {


const props = __props

const isIntersecting = ref()


const intersectionOptions = {
  handler(entry: IntersectionObserverEntry) {
    isIntersecting.value = entry.isIntersecting
  },
  cfg: {
    threshold: [0, 1],
    rootMargin: '800px',
  }
}

const isSliderVisible = computed(() => {
  return isIntersecting.value && props.showSlider;
})


return (_ctx: any,_cache: any) => {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _directive_intersection = _resolveDirective("intersection")!

  return (!__props.loading && __props.modelValue)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(CustomLink, {
          to: (__props.modelValue?.status === 'active' && __props.modelValue?.uuid ? { name: 'item-detail', params: { slug: __props.modelValue?.seo_slug, uuid: __props.modelValue.uuid, } } : undefined),
          class: "c-card-link-wrapper",
          target: __props.target,
          style: {"text-decoration":"none"}
        }, {
          default: _withCtx(() => [
            (isIntersecting.value)
              ? (_openBlock(), _createBlock(ItemCardImage, _mergeProps({
                  key: 0,
                  ref: "advertCardImageRef"
                }, _ctx.$props, {
                  data: __props.modelValue,
                  "has-slider": isSliderVisible.value,
                  "hide-navigation": _ctx.$q.screen.lt.sm
                }), _createSlots({ _: 2 }, [
                  (_ctx.$slots.actions)
                    ? {
                        name: "topRight",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "actions")
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1040, ["data", "has-slider", "hide-navigation"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_q_skeleton, {
                    square: "",
                    style: {"aspect-ratio":"3/4"},
                    animation: "blink"
                  })
                ])),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(__props.modelValue.title), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (__props.showDistance && __props.modelValue?.distance_in_km)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(__props.modelValue?.distance_in_km) + " km • ", 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(__props.modelValue.address?.city), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (__props.modelValue.offer_type === 'give_away' && __props.modelValue.item_type === 'offer' )
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" Zu verschenken ")
                        ], 64))
                      : (__props.modelValue.item_type === 'request')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _cache[0] || (_cache[0] = _createTextVNode(" Gesuch")),
                            (__props.modelValue.price)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, " bis " + _toDisplayString(!__props.modelValue.price ? '- €' :
                    _ctx.$filters.currency({
                      value: __props.modelValue.price,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })), 1))
                              : _createCommentVNode("", true)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (__props.modelValue.price)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(!__props.modelValue.price ? '- €' :
                      _ctx.$filters.currency({
                        value: __props.modelValue.price,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })) + "  ", 1)
                                ], 64))
                              : _createCommentVNode("", true),
                            (((__props.modelValue.price ?? undefined) === undefined && ['sale', 'rent'].includes(__props.modelValue.offer_type)) || __props.modelValue.is_negotiable)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(" VB ")
                                ], 64))
                              : _createCommentVNode("", true)
                          ], 64))
                  ])
                ])
              ])
            ])
          ]),
          _: 3
        }, 8, ["to", "target"])
      ])), [
        [_directive_intersection, intersectionOptions]
      ])
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_skeleton, {
            type: "rect",
            style: {"aspect-ratio":"3/4"},
            class: "rounded-borders"
          })
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_q_skeleton, { type: "text" }),
            _createVNode(_component_q_skeleton, {
              type: "text",
              width: "85%"
            }),
            _createVNode(_component_q_skeleton, {
              type: "text",
              width: "50%"
            }),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_q_skeleton, {
                type: "rect",
                style: {"width":"30%","height":"24px"}
              })
            ])
          ])
        ])
      ], 64))
}
}

})