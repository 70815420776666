import {defineStore} from 'pinia'
import StoryApi from 'src/apps/stories/api'

interface State {
  stories: object,
  storyList: Array<any>,
}


export const useStoryStore = defineStore('stories', {
  state: (): State => ({
    stories: {},
    storyList: [],
  }),

  getters: {},
  actions: {
    fetchStory(slug, cached = true) {
      return new Promise((resolve, reject) => {
        if (cached && this.stories[slug]) return resolve(this.stories[slug])
        return StoryApi.getStory(slug).then(resp => {
          this.stories[slug] = resp.data
          resolve(resp.data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    fetchStoriesList(cached = false) {
      return new Promise((resolve, reject) => {
        if (cached && this.storyList) return resolve(this.storyList)
        return StoryApi.getStories().then(resp => {
          this.storyList = resp.data
          resolve(resp.data)
        }).catch(err => {
          reject(err)
        })
      })
    }

  },
})
